import React, { useContext, useEffect, useState } from "react";
import { Button, IconGoogle } from "../../../components/atoms";
import {
  Alert,
  FlatpickerGroup,
  InputGroup,
  SelectGroup,
} from "../../../components/molecules";

import { InformPassengers, TuorList } from "../../../components/organisms";
import { AuthContext } from "../../../contexts/Auth/AuthContext";
import { LoadingContext } from "../../../contexts/LoadingCTX/LoadingContext";
import { useApi } from "../../../hooks/useApi";
import { useReservation } from "../../../hooks/useReservation";
import { Wrapper, WrapperTuorList, WrapperInformPassengers } from "./styles";
import { Dialog } from "../../../components/organisms";

export function Reservation() {
  const { applicationData } = useContext(AuthContext);
  const loadingContext = useContext(LoadingContext);
  const api = useApi();

  const [phoneErrorMessage, setPhoneErrorMessage] = useState<boolean>(false);
  const [childrenErrorMessage, setChildrenErrorMessage] =
    useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState(false);

  const {
    navigate,
    origin,
    setOrigin,
    destiny,
    setDestiny,
    client,
    setClient,
    going,
    setGoing,
    comeBack,
    setComeBack,
    amountOfAdults,
    setAmountOfAdults,
    amountOfChildren,
    setAmountOfChildren,
    goingData,
    setGoingData,
    comeBackData,
    setcomeBackData,
    choiceGoing,
    setChoiceGoing,
    choiceComeBack,
    setChoiceComeBack,
    enableButton,
    setEnableButton,
    passengers,
    setPassengers,

    handleSeekTour,
    generateTourTitle,
    updatesAdultPassengerData,
    updatesChildrenPassengerData,
    updatesAllOfThePassengers,
    thereIsATour,
    setThereIsATour,
  } = useReservation(applicationData, api);

  useEffect(() => {
    updatesAllOfThePassengers(passengers);

    setEnableButton(
      !(
        amountOfAdults > 0 &&
        choiceGoing !== "" &&
        !passengers.adults.reduce(
          (acc, adult) => (!adult.name || !adult.tel ? true : acc),
          false
        ) &&
        !passengers.children.reduce(
          (acc, child) => (!child.name ? true : acc),
          false
        ) &&
        !phoneErrorMessage
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountOfAdults, choiceGoing, passengers, phoneErrorMessage, goingData]);

  const payment = async () => {
    const passeioIda = goingData?.filter(
      (passeio) => passeio.codigo === +choiceGoing
    )[0];
    const passeioVolta = comeBackData?.filter(
      (passeio) => passeio.codigo === +choiceComeBack
    )[0];

    let reserva: Reservation = {
      cliente: client,
      redirectURL: "http://localhost:3000",
      email: applicationData.username,
      adultos: passengers.adults.map(({ name, tel }) => ({
        nome: name,
        identificacao: tel,
      })),
      criancas: passengers.children.map(({ name }) => ({ nome: name })),
      useradd:
        applicationData.acesso === "funcionario"
          ? applicationData.funcionario
          : 0,
      checkout: applicationData.checkout,
    };

    if (passeioIda) {
      const { codigo, conexao } = passeioIda;

      if (conexao)
        reserva = {
          ...reserva,
          ida: { passeio: codigo, conexao } || null,
        };
      else
        reserva = {
          ...reserva,
          ida: { passeio: codigo } || null,
        };
    }
    if (passeioVolta) {
      const { codigo, conexao } = passeioVolta;

      if (conexao)
        reserva = {
          ...reserva,
          volta: { passeio: codigo, conexao } || null,
        };
      else
        reserva = {
          ...reserva,
          volta: { passeio: codigo } || null,
        };
    }

    loadingContext.setLoading(true);

    try {
      await api.reserva(applicationData.token, reserva);
    } catch (error: any) {
      if (error.response.statusText === "Token expired [EMVCJWTException]") {
        navigate("/login");
      }
    }

    loadingContext.setLoading(false);
  };

  return (
    <div>
      <h1>Compra de passagens</h1>
      <div className="row mt-5">
        <div className="col-sm-12 col-lg-3">
          <SelectGroup
            value={origin}
            id="origem"
            label="Origem"
            options={applicationData.destinos.map((origem: Destino) => ({
              text: origem.nome,
              value: origem.codigo,
            }))}
            onChange={(e) => {
              setOrigin(e.target.value);

              if (origin !== "0" && destiny !== "0")
                handleSeekTour(setGoingData, going, origin, destiny);
            }}
          />
        </div>
        <div className="col-sm-12 col-lg-3">
          <SelectGroup
            value={destiny}
            id="destino"
            label="Destino"
            options={applicationData.destinos.map((destino: Destino) => ({
              text: destino.nome,
              value: destino.codigo,
            }))}
            onChange={(e) => {
              setDestiny(e.target.value);

              if (origin !== "0" && destiny !== "0")
                handleSeekTour(setGoingData, going, origin, destiny);
            }}
          />
        </div>
        <div className="col-sm-12 col-lg-6">
          <SelectGroup
            value={client}
            id="cliente"
            label="Cliente"
            readOnly={applicationData.acesso !== "funcionario"}
            options={applicationData.clientes.map((cliente: Destino) => ({
              text: cliente.nome,
              value: cliente.codigo,
            }))}
            onChange={(e) => {
              setClient(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-lg-3">
          <FlatpickerGroup
            label="Ida"
            id="data-ida"
            value={going}
            options={{ minDate: "today" }}
            onChange={([date]) => {
              setGoing(date);
              setChoiceGoing("");
              setGoingData(null);
              if (origin !== "0" && destiny !== "0")
                handleSeekTour(setGoingData, date, origin, destiny);

              setThereIsATour(true);
            }}
          />
        </div>
        <div className="col-sm-12 col-lg-3">
          <FlatpickerGroup
            className="col-sm-12 col-lg-2"
            label="Volta"
            id="data-volta"
            value={comeBack}
            options={{ minDate: "today" }}
            onChange={([date]) => {
              setComeBack(date);
              setChoiceComeBack("");
              setcomeBackData(null);
              handleSeekTour(setcomeBackData, date, destiny, origin);
              setThereIsATour(true);
            }}
          />
        </div>
        <div className="col-sm-12 col-lg-3">
          <InputGroup
            className="col-sm-12 col-lg-2"
            label="Adultos"
            id="adultos"
            type="number"
            value={amountOfAdults}
            autoComplete="off"
            onChange={(e) => {
              if (Number(e.target.value) <= 10 && Number(e.target.value) >= 1)
                setAmountOfAdults(Number(e.target.value));
            }}
            onKeyUp={() =>
              setPassengers({
                ...passengers,
                adults: Array(Number(amountOfAdults)).fill({
                  name: "",
                  tel: "",
                }),
              })
            }
          />
        </div>
        <div className="col-sm-12 col-lg-3">
          <InputGroup
            className="col-sm-12 col-lg-2"
            label="Crianças"
            messageError={
              childrenErrorMessage
                ? "Campo obrigatório! preencha com 0 (zero) caso não haja crianças."
                : ""
            }
            id="criancas"
            type="number"
            value={amountOfChildren!}
            autoComplete="off"
            onChange={(e) => {
              if (Number(e.target.value) >= 0 && Number(e.target.value) <= 10) {
                setAmountOfChildren(e.target.value);
                setChildrenErrorMessage(false);
              }
            }}
            onKeyUp={() =>
              setPassengers({
                ...passengers,
                children: Array(Number(amountOfChildren)).fill({
                  name: "",
                }),
              })
            }
          />
        </div>
      </div>

      <WrapperTuorList>
        {!thereIsATour ? (
          <Alert appearance="danger" title="INFORMAÇÃO IMPORTANTE">
            <p className="d-flex gap-2">
              <span>
                <IconGoogle>info</IconGoogle>
              </span>{" "}
              Não há nenhum passeio para essa data!
            </p>
          </Alert>
        ) : (
          ""
        )}

        <TuorList
          name="ida"
          show={goingData !== null && origin !== "0" && destiny !== "0"}
          value={choiceGoing}
          setValue={(value) => {
            setChoiceGoing(value);
          }}
          title={
            origin !== "0" && destiny !== "0"
              ? `Ida de ${generateTourTitle(
                  applicationData.destinos,
                  origin
                )} para ${generateTourTitle(applicationData.destinos, destiny)}`
              : ""
          }
          options={goingData}
        ></TuorList>

        <TuorList
          name="volta"
          show={comeBackData !== null && origin !== "0" && destiny !== "0"}
          value={choiceComeBack}
          setValue={(value) => {
            setChoiceComeBack(value);
          }}
          title={
            origin !== "0" && destiny !== "0"
              ? `Volta de ${generateTourTitle(
                  applicationData.destinos,
                  destiny
                )} para ${generateTourTitle(applicationData.destinos, origin)}`
              : ""
          }
          options={comeBackData}
        ></TuorList>
      </WrapperTuorList>

      <WrapperInformPassengers>
        <InformPassengers
          name="passageiros"
          show={amountOfAdults > 0 || Number(amountOfChildren!) > 0}
          title="Passageiros"
        >
          {amountOfAdults > 0 ? (
            <div>
              <h3>
                <IconGoogle className="adult">person</IconGoogle> Adultos
              </h3>
              {passengers.adults.map((_, index) => (
                <div className="row" key={index}>
                  <div className="col-6">
                    <InputGroup
                      value={`${passengers.adults[index].name}`}
                      onChange={(e) => {
                        const adults = updatesAdultPassengerData(
                          index,
                          "name",
                          e.target.value
                        );

                        setPassengers({
                          ...passengers,
                          adults,
                        });
                      }}
                      label={`Passageiro ${index + 1}`}
                    />
                  </div>
                  <div className="col-6">
                    <InputGroup
                      label="Celular"
                      value={`${passengers.adults[index].tel}`}
                      messageError={
                        phoneErrorMessage
                          ? "quantidade mínima de 10 caracteres"
                          : ""
                      }
                      onChange={(e) => {
                        e.target.value.length < 10
                          ? setPhoneErrorMessage(true)
                          : setPhoneErrorMessage(false);

                        const adults = updatesAdultPassengerData(
                          index,
                          "tel",
                          e.target.value
                        );

                        setPassengers({
                          ...passengers,
                          adults,
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}

          {Number(amountOfChildren!) > 0 ? (
            <div>
              <h3>
                <IconGoogle>child_care</IconGoogle> Crianças
              </h3>
              <div className="row">
                {passengers.children.map((children, index) => (
                  <div className="col-6" key={index}>
                    <InputGroup
                      value={`${passengers.children[index].name}`}
                      onChange={(e) => {
                        const children = updatesChildrenPassengerData(
                          index,
                          "name",
                          e.target.value
                        );

                        setPassengers({
                          ...passengers,
                          children,
                        });
                      }}
                      label={`Criança ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </InformPassengers>
      </WrapperInformPassengers>

      <Alert appearance="danger" title="ATENÇÃO" className="mt-5">
        <p>
          Em seu retorno <span>IMPORTANTE</span> respeitar Um tempo mínimo de 6
          horas para o horário do vôo. Após a conclusão da compra, o bilhete
          adquirido será enviado para o seu e-mail cadastrado neste sistema e
          torna-se <span>OBRIGATÓRIO</span> a impressão do mesmo para a
          apresentação em nosso guichê no dia do embarque.
        </p>
      </Alert>

      <Wrapper className="row mt-5">
        <Button
          appearance="primary"
          disabled={enableButton}
          onClick={() => {
            if (amountOfChildren === "0") setOpenDialog(true);
            else if (!amountOfChildren) setChildrenErrorMessage(true);
            else {
              payment();
            }
          }}
        >
          Pagar <IconGoogle>local_atm</IconGoogle>
        </Button>
      </Wrapper>

      <Dialog
        open={openDialog}
        title="Confirmação de passageiros"
        description="Confirma que não há crianças para esse passeio?"
        onShow={(action) => {
          setOpenDialog(action === "opened");
        }}
        actions={
          <>
            <Button
              appearance="success"
              autoFocus
              onClick={(e) => {
                setOpenDialog(false);
                payment();
              }}
            >
              Confirmar
            </Button>
          </>
        }
      />
    </div>
  );
}
