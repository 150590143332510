import React, { useContext, useEffect, useState } from "react";
import { SelectGroup } from "../../../components/molecules";
import { Pagination } from "../../../components/organisms";
import { Table } from "../../../components/organisms/Table";
import { AuthContext } from "../../../contexts/Auth/AuthContext";
import { api } from "../../../helpers";

export function MyReservations() {
  const {
    applicationData: { token, acesso, username },
  } = useContext(AuthContext);
  const [paginationControl, setPaginationControl] = useState<PaginationType>({
    offset: 0,
    limit: 5,
    count: 0,
  });

  const toLocaleDateString = (dateText: string) =>
    new Date(dateText).toLocaleDateString();

  const [myReservations, setMyReservations] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { TotalReservas, Reservas },
      } = await api.post(
        "/api/reserva/minhasreservas",
        {
          username,
          acesso,
          limit: paginationControl.limit,
          offset: paginationControl.offset,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const convertingDataToDisplay = (reservas: any) =>
        reservas.map((reserva: any) => {
          return {
            ...reserva,
            "Data da Reserva": toLocaleDateString(reserva["Data da Reserva"]),
            Embarque: toLocaleDateString(reserva.Embarque),
          };
        });

      setPaginationControl((current) => ({
        ...current,
        count: TotalReservas,
      }));

      const convertedDataForDisplay = convertingDataToDisplay(Reservas);
      setMyReservations(convertedDataForDisplay);
    })();
  }, [
    acesso,
    paginationControl.limit,
    paginationControl.offset,
    token,
    username,
  ]);

  return (
    <div>
      <h1>Minhas Reservas</h1>

      <div className="row mt-5">
        <SelectGroup
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12"
          value={String(paginationControl.limit)}
          id="resultsPerPage"
          label="Resultados por página"
          options={[
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            { text: "50", value: 50 },
          ]}
          onChange={(e) => {
            setPaginationControl({
              ...paginationControl,
              limit: Number(e.target.value !== "0" ? e.target.value : "5"),
              offset: 0,
            });
          }}
        />

        <Table data={myReservations}></Table>

        <Pagination
          className="col-12"
          data={paginationControl}
          dispatch={setPaginationControl}
        />
      </div>
    </div>
  );
}
