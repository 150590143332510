import shortid from "shortid";
import { IconGoogle, Link } from "../../atoms";
import {
  DetailsTable,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Wrapper,
  EmptyTable,
  EmptyTableWrapper,
} from "./styles";

interface RowContract {
  [key: string]: any | Object;
}

type TableProps = {
  data: RowContract[];
};

export function Table({ data }: TableProps) {
  const tableHead =
    Object.keys(data).length > 0 ? [...Object.keys(data[0]), "Ação"] : [];
  const tableBody =
    Object.keys(data).length > 0
      ? data.map((row, index) => {
          const newRow = [];
          for (const key in row) {
            if (tableHead.includes(key))
              newRow.push(
                <TableCell key={shortid.generate()}>{row[key]}</TableCell>
              );
          }
          newRow.push(
            <TableCell key={shortid.generate()}>
              <Link to={"/operacional/relreservas/" + row["Reserva"]}>
                Detalhes
              </Link>
            </TableCell>
          );
          return newRow;
        })
      : [[]];

  return Object.keys(data).length > 0 ? (
    <Wrapper>
      <DetailsTable>
        <TableHeader>
          <TableRow>
            {tableHead.map((columnName) => (
              <TableColumn key={shortid.generate()}>{columnName}</TableColumn>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {tableBody.map((row) => (
            <TableRow key={shortid.generate()}>
              {row.map((tCell) => tCell)}
            </TableRow>
          ))}
        </TableBody>
      </DetailsTable>
    </Wrapper>
  ) : (
    <EmptyTableWrapper>
      <EmptyTable>
        <IconGoogle>error_outline</IconGoogle> Não encontramos nenhuma reserva
        para apresentação.
      </EmptyTable>
    </EmptyTableWrapper>
  );
}
