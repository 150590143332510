import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

export function AuthRequire({ children }: { children: JSX.Element }) {
  const auth = useContext(AuthContext);

  if (auth.loading) {
    return <></>;
  }

  if (!auth.logged) {
    return <Navigate to="/auth/login" />;
  }

  return children;
}
