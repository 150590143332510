import styled from "styled-components";

export const TuorListContainer = styled.fieldset`
  border: 1px solid ${({ theme }) => theme.colors.secondary + "55"};
  border-radius: 8px;
  overflow: hidden;
  flex: 1;
`;

export const TuorListHeader = styled.legend`
  background-color: ${({ theme }) => theme.colors.primary_200};
  color: ${({ theme }) => theme.colors.light};
  padding: 10px 15px;

  h2 {
    font-size: 25px;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;

export const TuorListBody = styled.div`
  padding: 10px 15px;
`;

export const Options = styled.label`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;

  input {
    accent-color: ${({ theme }) => theme.colors.primary};
  }

  &:has(input:checked),
  &:hover {
    background: ${({ theme }) => theme.colors.background_800 + "23"};
  }
`;
export const OptionsPrice = styled.label`
  pointer-events: none;
`;
export const OptionsInfo = styled.label`
  pointer-events: none;
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;
export const OptionsInfoChip = styled.label`
  background-color: ${({ theme }) => theme.colors.primary_200};
  color: ${({ theme }) => theme.colors.light};
  padding-inline: 8px;
  border-radius: 10px;
`;
