import React from "react";
import { NavLink } from "react-router-dom";
import { IconGoogle } from "../../atoms";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon?: string;
  text?: string;
  expanded?: boolean;
}
function NavListButton({ icon, text, onClick, expanded = false }: ButtonProps) {
  return (
    <button onClick={onClick} className={expanded ? "expanded" : ""}>
      <IconGoogle>{icon}</IconGoogle>
      <span>{text}</span>
      <IconGoogle className="group-toggle">chevron_right</IconGoogle>
    </button>
  );
}

interface LinkProps {
  icon?: string;
  text?: string;
  to?: string;
}
function NavListLink({ icon, text, to = "" }: LinkProps) {
  return (
    <NavLink to={to}>
      <IconGoogle className="icon-children">{icon}</IconGoogle>
      <span>{text}</span>
    </NavLink>
  );
}

export const NavListItem = {
  NavListButton,
  NavListLink,
};
