import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing:border-box;
    
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.light};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.primary + "dd"};
    }
  }

  body{
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    font-family: 'Public Sans', sans-serif;
    min-height: 100vh;
  }

  
  .flatpickr-day.selected{
    background: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};

    &:hover{
      background: ${({ theme }) => theme.colors.primary + "88"};
      border-color: ${({ theme }) => theme.colors.primary + "88"};
    }
  }

  .MuiInputBase-root{
    max-height:40px
  }
`;
