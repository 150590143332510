import { Wrapper } from "./styles";
import {
  Dialog as DialogMui,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Button } from "../../atoms";

type DialogProps = {
  open?: boolean;
  title?: string;
  description?: string;
  actions?: JSX.Element;
  onShow?: (event: "opened" | "closed") => void;
};

export enum DialogEvents {
  OPEN,
  CLOSE,
}

export function Dialog({
  open = true,
  actions,
  onShow,
  title,
  description,
}: DialogProps) {
  const [openDialog, setOpenDialog] = useState<DialogEvents>(
    DialogEvents.CLOSE
  );

  const dispatch = (action: DialogEvents) => {
    if (onShow) onShow(action === DialogEvents.OPEN ? "opened" : "closed");
  };

  useEffect(() => {
    setOpenDialog(open ? DialogEvents.OPEN : DialogEvents.CLOSE);
  }, [open]);

  return (
    <Wrapper>
      <DialogMui
        open={openDialog === DialogEvents.OPEN}
        onClose={() => dispatch(DialogEvents.CLOSE)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        className="dialog"
      >
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            appearance="danger"
            onClick={() => dispatch(DialogEvents.CLOSE)}
          >
            Cancelar
          </Button>
          {actions}
        </DialogActions>
      </DialogMui>
    </Wrapper>
  );
}
