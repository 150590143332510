import axios, { AxiosResponse, AxiosError } from "axios";
import { DefaultTheme } from "styled-components";

import { defaultTheme } from "../../styles/themes/defaultTheme";

const api = axios.create({
  baseURL: "",
});

const fetchApi = {
  async getTheme(): Promise<DefaultTheme> {
    return api
      .get("/theme")
      .then(({ data }: AxiosResponse): DefaultTheme => data)
      .catch((error: AxiosError): DefaultTheme => defaultTheme);
  },
};

export { fetchApi };
