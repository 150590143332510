import styled from "styled-components";

export const Wrapper = styled.div``;

export const ButtonWrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  button {
    max-width: 250px;
  }

  @media (max-width: 576px) {
    align-items: center;
    justify-content: center;

    button {
      max-width: 100%;
    }
  }
`;

export const DetailsContainer = styled.div`
  margin-top: 2.5rem;
  display: flex;
  background-color: #f9fdff;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.75rem;
  gap: 1rem;
  box-shadow: 0px 2px 10px #000000aa;
`;
export const DetailsGroup = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const DetailType = styled.span`
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
  opacity: 0.9;
`;

export const Detail = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export const Description = styled.span`
  font-size: 1.1rem;
  color: #505050;
`;
