import styled from "styled-components";

export const InformPassengersContainer = styled.fieldset`
  border: 1px solid ${({ theme }) => theme.colors.secondary + "55"};
  border-radius: 8px;
  overflow: hidden;
  flex: 1;
`;

export const InformPassengersHeader = styled.legend`
  background-color: ${({ theme }) => theme.colors.primary_200};
  color: ${({ theme }) => theme.colors.light};
  padding: 10px 15px;

  h2 {
    font-size: 25px;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;

export const InformPassengersBody = styled.div`
  padding: 10px 15px;

  display: flex;
  gap: 15px;

  & > div {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.secondary + "55"};
    padding: 10px 15px;
    width: 100%;

    h3 {
      display: flex;
      align-items: center;
      gap: 10px;
      color: ${({ theme }) => theme.colors.primary};

      .adult {
        font-size: 30px;
      }
    }
  }

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const Options = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;

  input {
    accent-color: ${({ theme }) => theme.colors.primary};
  }

  &:has(input:checked),
  &:hover {
    background: ${({ theme }) => theme.colors.background_800 + "23"};
  }
`;
export const OptionsPrice = styled.label`
  pointer-events: none;
`;
export const OptionsInfo = styled.label`
  pointer-events: none;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const OptionsInfoChip = styled.label`
  background-color: ${({ theme }) => theme.colors.primary_200};
  color: ${({ theme }) => theme.colors.light};
  padding-inline: 8px;
  border-radius: 10px;
`;
