import { useState } from "react";
import { Loading } from "../../components/atoms";
import { LoadingContext } from "./LoadingContext";

export function LoadingProvider({ children }: { children: JSX.Element }) {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <Loading active={loading} />
      {children}
    </LoadingContext.Provider>
  );
}
