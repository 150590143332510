import styled from "styled-components";

export const AsideContainer = styled.aside`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 240px;
  translate: 0px;
  transition: translate 0.5s linear;
  height: 100vh;
  z-index: 1299;

  div {
    width: 240px;
  }

  @media (max-width: 992px) {
    display: flex;
    width: 100%;
    &:not(.show) {
      translate: -992px;

      .backdrop {
        display: none;
      }
    }
  }
`;

export const AsideHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 14px;
  background-color: ${({ theme }) => theme.colors.background};

  img {
    max-width: 70%;
  }
`;

export const ToggleAside = styled.input`
  position: relative;
  cursor: pointer;
  height: 0;
  width: 40px;

  ::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 20px;
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.colors.light};
  }

  ::after {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.light};
    left: calc(50% - 15px);
    top: calc(50% - 6px);
    width: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    transition: left 0.3s ease-in-out;
  }

  :checked::after {
    left: calc(50% - (-3px));
  }
`;

export const AsideBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const BackDrop = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100vw;
  flex: 1;
  height: 100vh;

  @media (min-width: 992px) {
    display: none;
  }
`;
