import { createContext } from "react";

export type NotificationContextType = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  type?: "primary" | "danger" | "success";
  setType: React.Dispatch<
    React.SetStateAction<"primary" | "danger" | "success">
  >;
  text: React.ReactNode;
  setText: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

export const NotificationContext = createContext<NotificationContextType>(
  null!
);
