import {
  TuorListContainer,
  TuorListHeader,
  TuorListBody,
  Options,
  OptionsInfo,
  OptionsPrice,
  OptionsInfoChip,
} from "./styles";

type TuorListProps = {
  title: string;
  show?: boolean;
  options?: Passeios | null;
  name?: string;
  value?: string | number | readonly string[] | undefined;
  setValue?: (value: string | number) => void;
};

export function TuorList({
  title,
  show = false,
  options,
  name = "",
  value,
  setValue,
}: TuorListProps) {
  const getHourForLabel = (dateString: string) => {
    dateString = dateString.replace("Z", "");
    const date = new Date(dateString);

    return `${String(date.getHours()).padStart(2, "0")}:${String(
      date.getMinutes()
    ).padStart(2, "0")}`;
  };

  return (
    <>
      {show ? (
        <TuorListContainer>
          <TuorListHeader>
            <h2>{title}</h2>
          </TuorListHeader>
          <TuorListBody>
            {options?.map((option, index) => (
              <Options key={index}>
                <input
                  type="radio"
                  name={name}
                  id=""
                  value={value}
                  onChange={() => {
                    if (setValue) setValue(option.codigo);
                  }}
                />
                <OptionsInfo>
                  {getHourForLabel(option.horario)} Transporte:{" "}
                  <strong>{option.barconome}</strong>
                  <OptionsInfoChip>
                    vagas disponíveis: {option.disponivel}
                  </OptionsInfoChip>
                </OptionsInfo>
                <OptionsPrice>
                  {Number(option.preco).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </OptionsPrice>
              </Options>
            ))}
          </TuorListBody>
        </TuorListContainer>
      ) : (
        ""
      )}
    </>
  );
}
