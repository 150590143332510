import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthRequire } from "../contexts/Auth/AuthRequire";
import { Login, AuthScreen, Home } from "../pages";
import { Employees, Partners, MyReservations, Reservation } from "../pages";
import { ResetPassword } from "../pages/AuthScreen/ResetPassword";
import { Details } from "../pages/Operational/MyReservations/Details";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="auth/:screen" element={<AuthScreen />} />
        <Route path="auth/login" element={<Login />} />

        <Route
          path="/"
          element={
            <AuthRequire>
              <Home />
            </AuthRequire>
          }
        >
          <Route path="cadastros/funcionarios" element={<Employees />} />
          <Route path="cadastros/parceiros" element={<Partners />} />

          <Route path="auth/alterar-senha" element={<ResetPassword />} />

          <Route path="operacional/reservas" element={<Reservation />} />
          <Route path="operacional/relreservas" element={<MyReservations />} />
          <Route
            path="operacional/relreservas/:reservationID"
            element={<Details />}
          />
        </Route>

        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}
