// import "flatpickr/dist/themes/material_blue.css";
import "flatpickr/dist/themes/dark.css";
import { Portuguese } from "flatpickr/dist/l10n/pt";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";

export type FlatpickerProps = DateTimePickerProps &
  Omit<
    React.ComponentPropsWithoutRef<"input">,
    "children" | "value" | "onChange"
  > & {};

const defaulOptions = {
  locale: Portuguese,
  dateFormat: "d/m/Y", // H:i
  enableTime: false,
};

export function Flatpicker({ value, onChange, options }: FlatpickerProps) {
  return (
    <Flatpickr
      options={{
        ...defaulOptions,
        ...options,
      }}
      data-enable-time
      value={value}
      onChange={onChange}
    />
  );
}
