import styled from "styled-components";

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  appearance: "primary" | "secondary" | "danger" | "success" | "dark";
};

export const ButtonComponent = styled.button<Props>`
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;

  &:not(:disabled) {
    background-color: ${({ theme, appearance }) =>
      appearance === "primary"
        ? theme.colors.primary
        : appearance === "secondary"
        ? theme.colors.secondary
        : appearance === "danger"
        ? theme.colors.danger
        : appearance === "success"
        ? theme.colors.success
        : appearance === "dark"
        ? theme.colors.dark
        : "initial"};

    font-weight: ${({ theme }) => theme.fonts?.weight.medium500};
    color: ${({ theme }) => theme.colors.light};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme, appearance }) =>
        appearance === "primary"
          ? theme.colors.primary + "cc"
          : appearance === "secondary"
          ? theme.colors.secondary + "cc"
          : appearance === "danger"
          ? theme.colors.danger + "cc"
          : appearance === "success"
          ? theme.colors.success + "cc"
          : appearance === "dark"
          ? theme.colors.dark + "cc"
          : "initial"};
    }
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px solid #ccc;
  }
`;
