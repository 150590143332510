import styled from "styled-components";

type AlertWrapperProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  appearance?: "primary" | "secondary" | "danger" | "success" | "dark";
};

export const AlertWrapper = styled.div<AlertWrapperProps>`
  text-align: justify;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.dark};
  padding: 15px 20px;

  &::before {
  }

  h3 {
    font-size: 24px;
  }

  h3,
  span {
    color: ${({ theme, appearance }) =>
      appearance === "primary"
        ? theme.colors.primary
        : appearance === "secondary"
        ? theme.colors.secondary
        : appearance === "danger"
        ? theme.colors.danger
        : appearance === "success"
        ? theme.colors.success
        : appearance === "dark"
        ? theme.colors.dark
        : "initial"};
  }
`;

export const AlertContent = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  span {
    line-height: 0;
  }
`;
