import {
  InformPassengersContainer,
  InformPassengersHeader,
  InformPassengersBody,
} from "./styles";

type InformPassengersProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  title: string;
  show?: boolean;
  name?: string;
};

export function InformPassengers({
  title,
  show = false,
  children,
  className,
}: InformPassengersProps) {
  return (
    <>
      {show ? (
        <InformPassengersContainer className={className}>
          <InformPassengersHeader>
            <h2>{title}</h2>
          </InformPassengersHeader>
          <InformPassengersBody>{children}</InformPassengersBody>
        </InformPassengersContainer>
      ) : (
        ""
      )}
    </>
  );
}
