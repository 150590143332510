import { createContext } from "react";

export type AuthContextType = {
  user: User | null;
  logged: boolean;
  login: (user: User) => Promise<boolean>;
  signout: () => void;
  loading: boolean;
  applicationData: any;
};

export const AuthContext = createContext<AuthContextType>(null!);
