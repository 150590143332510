import React from "react";
import { NavLink } from "react-router-dom";

import { LinkComponent } from "./styles";

interface LinkProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  appearance?: "primary" | "secondary" | "danger" | "success" | "dark";
  to?: string;
}

export function Link({
  appearance = "primary",
  children,
  href = "",
  to,
}: LinkProps) {
  return (
    <LinkComponent appearance={appearance}>
      <NavLink to={href || to!}>{children}</NavLink>
    </LinkComponent>
  );
}
