import styled from "styled-components";

type Props = {
  appearance: "primary" | "secondary" | "danger" | "success" | "dark";
};

export const LinkComponent = styled.span<Props>`
  a {
    color: ${({ theme, appearance }) =>
      appearance === "primary"
        ? theme.colors.primary
        : appearance === "secondary"
        ? theme.colors.secondary
        : appearance === "danger"
        ? theme.colors.danger
        : appearance === "success"
        ? theme.colors.success
        : appearance === "dark"
        ? theme.colors.dark
        : "initial"};
    font-weight: ${({ theme }) => theme.fonts?.weight.medium500};
    transition: all 0.3s ease-in-out;
    text-decoration: none;

    &:hover {
      color: ${({ theme, appearance }) =>
        appearance === "primary"
          ? theme.colors.primary + "bb"
          : appearance === "secondary"
          ? theme.colors.secondary + "bb"
          : appearance === "danger"
          ? theme.colors.danger + "bb"
          : appearance === "success"
          ? theme.colors.success + "bb"
          : appearance === "dark"
          ? theme.colors.dark + "bb"
          : "initial"};

      text-decoration: underline;
    }
  }
`;
