import { DefaultTheme } from "styled-components";

export const defaultTheme: DefaultTheme = {
  colors: {
    primary: "#00529c",
    primary_200: "#0087ca",
    secondary: "#25293c",
    danger: "#e44f53",
    success: "#28c76f",
    dark: "#eeeeee",
    light: "#ffffff",

    background_800: "#727272",
    background: "#ffffff",
    text: "#2f3349",
  },
  fonts: {
    weight: {
      light300: "300",
      regular400: "400",
      medium500: "500",
      semiBold600: "600",
      bold700: "700",
    },
  },
};
