import React from "react";

import { InputComponent } from "./styles";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  transparent?: boolean;
}

export function Input({
  type = "text",
  transparent = false,
  placeholder,
  className = "",
  onChange,
  onKeyDown,
  onKeyUp,
  defaultValue,
  value,
  autoComplete,
  readOnly,
  maxLength,
  id = "",
}: InputProps) {
  return (
    <InputComponent
      id={id}
      type={type}
      transparent={transparent}
      placeholder={placeholder}
      className={className}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      autoComplete={autoComplete}
      readOnly={readOnly}
      maxLength={maxLength}
      defaultValue={defaultValue}
      value={value}
    />
  );
}
