import { AlertWrapper, AlertContent } from "./styles";

type AlertProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  appearance?: "primary" | "secondary" | "danger" | "success" | "dark";
  title?: string;
};

export function Alert({
  appearance = "primary",
  children,
  title,
  className,
}: AlertProps) {
  return (
    <AlertWrapper appearance={appearance} className={className}>
      <h3>{title}</h3>
      <AlertContent>{children}</AlertContent>
    </AlertWrapper>
  );
}
