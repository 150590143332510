import React from "react";

import { MessageErrorComponent } from "./styles";

interface MessageErrorProps {
  children: React.ReactNode;
}

export function MessageError({ children }: MessageErrorProps) {
  return (
    <>
      {children ? (
        <MessageErrorComponent>{children}</MessageErrorComponent>
      ) : (
        ""
      )}
    </>
  );
}
