import styled from "styled-components";

type Props = {
  appearance?: "primary" | "secondary" | "danger" | "success" | "dark";
};

export const CheckboxComponent = styled.input<Props>`
  accent-color: ${({ theme, appearance }) =>
    appearance === "primary"
      ? theme.colors.primary
      : appearance === "secondary"
      ? theme.colors.secondary
      : appearance === "danger"
      ? theme.colors.danger
      : appearance === "success"
      ? theme.colors.success
      : appearance === "dark"
      ? theme.colors.dark
      : "initial"};

  scale: 1.4;
`;
