import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-top: 0.5rem;

  @media (max-width: 576px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;

export const PaginationActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 0.2rem;
`;

export const PaginationLegend = styled.span`
  margin-left: 0.2rem;
  font-size: 14px;
  color: #999;
`;

export const PaginationButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  min-width: 24px;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.dark};
  border: 1px solid ${({ theme }) => theme.colors.dark};

  &.active {
    color: ${({ theme }) => theme.colors.light};
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &:not(.active):disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.text + "66"};
  }
`;
