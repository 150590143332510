import { NavList } from "../NavList";

import {
  AsideContainer,
  AsideHeaderContainer,
  ToggleAside,
  AsideBodyContainer,
  BackDrop,
} from "./styles";

type AsideProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
> & {
  logo?: JSX.Element;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
  state?: boolean;
};

export function Aside({ logo, toggle, state, className = "" }: AsideProps) {
  return (
    <AsideContainer className={className + `${state ? " show" : ""}`}>
      <div>
        <AsideHeaderContainer>
          {logo ? logo : ""}
          {state ? (
            <ToggleAside
              type="checkbox"
              defaultChecked={true}
              aria-label="mostrar ou esconder menu lateral"
            />
          ) : (
            ""
          )}
        </AsideHeaderContainer>
        <AsideBodyContainer>
          <NavList />
        </AsideBodyContainer>
      </div>
      <BackDrop className="backdrop" onClick={(e) => toggle(!state)}></BackDrop>
    </AsideContainer>
  );
}
