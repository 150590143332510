import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "Public Sans", sans-serif;
  max-height: 40px;
  margin-top: 15px;

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-family: inherit;

    &.Mui-focused {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div[role="button"],
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
  svg {
    color: ${({ theme }) => theme.colors.text};
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    max-height: 37px;
    border-radius: 8px;

    &:hover {
      border-color: ${({ theme }) => theme.colors.secondary + "55"};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      font-family: inherit;
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary + "55"};
  }
`;
