import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import { Button, Link, MessageError } from "../../components/atoms";
import { InputGroup } from "../../components/molecules";

// Components Styled Component
import {
  Main,
  ImageContainer,
  ImageWrapper,
  WrapperForm,
  LinkStyle,
} from "./styles";

// Imagens
import loginIlustration from "../../assets/img/banner_inicial.jpg";
import logoBiotur from "../../assets/img/logo_biotur.png";

// auth
// import authSocialMedia from "../../services/authSocialMedia";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { LoadingContext } from "../../contexts/LoadingCTX/LoadingContext";

export function Login() {
  const auth = useContext(AuthContext);
  const loadingContext = useContext(LoadingContext);
  const navigate = useNavigate();

  const [user, setUser] = useState<userRequest>({
    usuario: null,
    senha: null,
  });

  const [messageError, setMessageError] = useState(false);

  // const fetchApiGoogle = async () => {
  //   authSocialMedia.loginGoogle().then(({ user }) => {
  //     console.log(user?.displayName, user?.email, user?.photoURL);
  //   });
  // };
  // const fetchApiFacebook = async () => {
  //   authSocialMedia.loginFacebook().then(({ user }) => {
  //     console.log(user?.displayName, user?.email, user?.photoURL);
  //   });
  // };
  const fetchApiAuth = async () => {
    loadingContext.setLoading(true);
    if (user.usuario && user.senha) {
      try {
        const isLogged = await auth.login(user);

        if (isLogged) {
          if (auth.applicationData.acesso === "TROCAR SENHA") {
            navigate("/auth/alterar-senha");
          } else {
            navigate("/");
          }
        }
      } catch (error) {
        setMessageError(true);
      }
      loadingContext.setLoading(false);
    }
  };

  return (
    <Main className="container-fluid vh-100">
      <div className="row vh-100 align-items-center">
        <ImageWrapper className="col-sm-12 col-md-6 col-lg-8 d-none d-md-block">
          <ImageContainer>
            <img
              src={loginIlustration}
              alt="Imagem ilustrativa"
              className="ilustracao"
            />
          </ImageContainer>
        </ImageWrapper>

        <WrapperForm className="col-sm-12 col-md-6 col-lg-4">
          <img src={logoBiotur} alt="Logotipo da Biotur" />

          <h1>Controle de Acesso</h1>
          <p>Uso de conta local</p>

          <MessageError>
            {messageError ? "Usuário ou senha inválidos!" : ""}
          </MessageError>

          <InputGroup
            id="user"
            label="Email"
            onChange={(e) => {
              setMessageError(false);
              setUser({
                ...user,
                usuario: e.target.value,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                fetchApiAuth();
              }
            }}
            required
          />

          <InputGroup
            id="pass"
            type="password"
            label="Senha"
            autoComplete="on"
            hasPasswordType={true}
            onChange={(e) => {
              setMessageError(false);
              setUser({
                ...user,
                senha: e.target.value,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                fetchApiAuth();
              }
            }}
            required
          />

          <LinkStyle className="text-center">
            <Link to="/auth/esqueci-minha-senha">Esqueci minha senha</Link>
          </LinkStyle>

          {/* <Remember>
            <Checkbox id="remember" appearance="primary" />
            <label htmlFor="remember">Lembre-se de mim?</label>
          </Remember> */}

          <Button appearance="primary" onClick={fetchApiAuth}>
            Entrar
          </Button>

          <span className="text-center">
            <Link to="/auth/cadastro">Cadastre-se</Link> caso não tenha uma
            conta.
          </span>

          {/* <Divider>
            <hr />
            ou
            <hr />
          </Divider>

          <WrapperSocialAuth>
            <Button appearance="primary" onClick={fetchApiFacebook}>
              <FacebookIcon />
              Facebook
            </Button>
            <Button appearance="primary" onClick={fetchApiGoogle}>
              <GoogleIcon />
              Google
            </Button>
          </WrapperSocialAuth> */}
        </WrapperForm>
      </div>
    </Main>
  );
}
