import { api } from "../helpers";

export const useApi = () => {
  return {
    login: async (user: User) => {
      const urlClient =
        window.location.origin === "http://localhost:3000"
          ? "https://transferlima.azurewebsites.net"
          : window.location.origin;
      const { data } = await api.post(`/login?urlClient=${urlClient}`, {
        ...user,
      });
      return data;
    },
    destinos: async (token: string) => {
      const { data } = await api.get("/api/destinos", {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    },
    clientes: async (token: string) => {
      const { data } = await api.get("/api/clientes/0", {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    },
    passeios: async (
      token: string,
      {
        origin,
        destiny,
        date,
      }: {
        origin: string;
        destiny: string;
        date: string;
      }
    ): Promise<Passeios> => {
      const { data } = await api.get(
        `/api/passeios/disponiveisconexao/${date}/${origin}/${destiny}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return data;
    },
    reserva: async (token: string, reservas: Reservation) => {
      if (+reservas.cliente !== 231) {
        const { data: pdf } = await api.post("/api/reserva", reservas, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        });

        let alink = document.createElement("a");
        alink.href = window.URL.createObjectURL(pdf);
        alink.download = "voucher.pdf";
        alink.click();
      } else {
        const { data: urlRedirect } = await api.post("/api/reserva", reservas, {
          headers: { Authorization: `Bearer ${token}` },
        });

        window.location.href = urlRedirect;
      }
    },
    minhasReservas: async (
      token: string,
      {
        username = "manuelamador@hotmail.com",
        acesso = "venda direta",
        limit,
        offset,
      }: any
    ) => {
      const { data } = await api.post(
        "/api/reserva/minhasreservas",
        { username, acesso, limit, offset },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return data;
    },
    detalheReserva: async (token: string, username: string, id: string) => {
      const {
        data: [detail],
      } = await api.get(`/api/reserva/${username}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return detail;
    },
    criarConta: async (username: string) => {
      const { data, status } = await api.post(
        `/api/usuario/vendadireta?email=${username}`
      );
      return { data, status };
    },
    esqueceuSenha: async (username: string) => {
      const { data, status } = await api.get(
        `/api/usuario/recuperarsenha/1/${username}`
      );
      return { data, status };
    },
    alterarSenha: async (
      token: string,
      username: string,
      oldPassword: string,
      newPassword: string
    ) => {
      const { data, status } = await api.post(
        `/api/usuario/trocarsenha?usuario=${username}&vsenhaantiga=${oldPassword}&vnovasenha=${newPassword}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return { data, status };
    },
  };
};
