import { useEffect, useState } from "react";
import { Notification } from "../../components/molecules";
import { NotificationContext } from "./NotificationContext";

export function NotificationProvider({ children }: { children: JSX.Element }) {
  const [show, setShow] = useState<boolean>(false);
  const [type, setType] = useState<"primary" | "danger" | "success">("success");
  const [text, setText] = useState<React.ReactNode>("success");

  useEffect(() => {
    const time = setTimeout(() => {
      setShow(false);
      clearTimeout(time);
    }, 5000);
  }, [setShow, show]);

  return (
    <NotificationContext.Provider
      value={{ show, setShow, type, setType, text, setText }}
    >
      <Notification type={type} show={show} setShow={setShow}>
        {text}
      </Notification>
      {children}
    </NotificationContext.Provider>
  );
}
