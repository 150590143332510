import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../../contexts/LoadingCTX/LoadingContext";

import { convertDateToPattern as dateFormat } from "../../helpers";

export function useReservation(applicationData: any, api: any) {
  const navigate = useNavigate();
  const loadingContext = useContext(LoadingContext);

  const [thereIsATour, setThereIsATour] = useState(true);

  const [origin, setOrigin] = useState("0");
  const [destiny, setDestiny] = useState("0");
  const [client, setClient] = useState(
    applicationData.acesso !== "funcionario" ? applicationData.funcionario : "0"
  );

  const [going, setGoing] = useState(new Date());
  const [comeBack, setComeBack] = useState<Date | string>("");

  const [amountOfAdults, setAmountOfAdults] = useState(1);
  const [amountOfChildren, setAmountOfChildren] = useState<string>("");

  const [goingData, setGoingData] = useState<Passeios | null>(null);
  const [comeBackData, setcomeBackData] = useState<Passeios | null>(null);

  const [choiceGoing, setChoiceGoing] = useState<number | string>("");
  const [choiceComeBack, setChoiceComeBack] = useState<number | string>("");

  const [enableButton, setEnableButton] = useState(true);

  const [passengers, setPassengers] = useState<Passengers>({
    adults: [
      {
        name: "",
        tel: "",
      },
    ],
    children: [],
  });

  const handleSeekTour = async (
    setState: React.Dispatch<React.SetStateAction<Passeios | null>>,
    date: Date,
    originId: string,
    destinyId: string
  ) => {
    loadingContext.setLoading(true);
    try {
      const data = await api.passeios(applicationData.token, {
        origin: originId,
        destiny: destinyId,
        date: dateFormat(date),
      });

      if (data.length > 0) {
        setState(data);
        setThereIsATour(true);
      } else {
        setThereIsATour(false);
      }
    } catch (error: any) {
      if (error.response?.statusText === "Token expired [EMVCJWTException]") {
        navigate("/login");
      }
    }

    loadingContext.setLoading(false);
  };

  const generateTourTitle = (passeios: Destinos, codigo: string) => {
    const [result] = passeios.filter(
      (passeio: Destino) => passeio.codigo === Number(codigo)
    );

    return result.nome;
  };

  const updatesAdultPassengerData = (
    index: number,
    key: string,
    value: string
  ) => {
    return passengers.adults.map((adult, i) => {
      if (index === i) return { ...adult, [key]: value };
      else return adult;
    });
  };
  const updatesChildrenPassengerData = (
    index: number,
    key: string,
    value: string
  ) => {
    return passengers.children.map((child, i) => {
      if (index === i) return { ...child, [key]: value };
      else return child;
    });
  };

  const updatesAllOfThePassengers = (passengers: Passengers) => {
    setPassengers(passengers);
  };

  return {
    navigate,
    origin,
    setOrigin,
    destiny,
    setDestiny,
    client,
    setClient,
    going,
    setGoing,
    comeBack,
    setComeBack,
    amountOfAdults,
    setAmountOfAdults,
    amountOfChildren,
    setAmountOfChildren,
    goingData,
    setGoingData,
    comeBackData,
    setcomeBackData,
    choiceGoing,
    setChoiceGoing,
    choiceComeBack,
    setChoiceComeBack,
    enableButton,
    setEnableButton,
    passengers,
    setPassengers,

    handleSeekTour,
    generateTourTitle,
    updatesAdultPassengerData,
    updatesChildrenPassengerData,
    updatesAllOfThePassengers,

    thereIsATour,
    setThereIsATour,
  };
}
