import React from "react";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

import { Wrapper } from "./styles";
import { MessageError } from "../../atoms";

interface SelectGroupProps {
  id?: string;
  label?: string;
  options?: { value: number; text: string }[];
  value: string;
  onChange?:
    | ((event: SelectChangeEvent<string>, child: React.ReactNode) => void)
    | undefined;
  readOnly?: boolean;
  messageError?: string;
  className?: string;
}

export function SelectGroup({
  id,
  label,
  options,
  value = "Selecione...",
  onChange,
  readOnly,
  messageError,
  className,
}: SelectGroupProps) {
  return (
    <>
      <Wrapper className={className}>
        <FormControl fullWidth>
          <InputLabel id={id}>{label}</InputLabel>
          <Select
            labelId={id}
            value={value}
            label={label}
            onChange={onChange}
            readOnly={readOnly}
          >
            <MenuItem value="0">Selecione...</MenuItem>
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Wrapper>
      <MessageError>{messageError}</MessageError>
    </>
  );
}
