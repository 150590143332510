import React from "react";
import { Flatpicker, MessageError } from "../../atoms";
import { FlatpickerProps } from "../../atoms/Flatpicker";

import { Wrapper } from "./styles";

interface FlatpickerGroupProps extends FlatpickerProps {
  label?: string;
  messageError?: string;
}

export function FlatpickerGroup({
  label = "",
  className,
  messageError,
  ...props
}: FlatpickerGroupProps) {
  return (
    <>
      <Wrapper className={className}>
        {label ? <label htmlFor={props.id}>{label}</label> : ""}
        <Flatpicker {...props} />
      </Wrapper>
      <MessageError>{messageError}</MessageError>
    </>
  );
}
