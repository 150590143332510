import styled from "styled-components";

type Props = {
  transparent: boolean;
};

export const InputComponent = styled.input<Props>`
  color: ${(props) => props.theme.colors.secondary};
  background: ${(props) =>
    props.transparent ? props.theme.colors.background : "#fff"};
  border: none;
  outline: none;
  font-weight: ${(props) => props.theme.fonts?.weight.regular400};

  &::placeholder {
    font-weight: ${(props) => props.theme.fonts?.weight.semiBold600};
  }
`;
