import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`;

export const ButtonWrapper = styled.div`
  margin: 1.25rem 0;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.dark};
  padding: 0;
  border-radius: 15px;
  height: 100%;
  overflow: hidden;

  & .ilustracao {
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
  }

  /* & .base-fundo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  } */
`;

export const ImageWrapper = styled.div`
  height: 100%;
  padding: 40px;
  padding-right: 0;
`;

export const WrapperForm = styled.form`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;

  h1 {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 1.75rem;
    font-weight: 600;
    opacity: 0.9;
    margin: 15px 0;
  }
`;

export const Divider = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  hr {
    flex: 1;
  }
`;
