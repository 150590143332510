import React, { useState } from "react";
import { IconGoogle, Input, MessageError } from "../../atoms";

import { Wrapper } from "./styles";

interface InputGroupProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  transparent?: boolean;
  label?: string;
  messageError?: string;
  hasPasswordType?: boolean;
  type?: React.HTMLInputTypeAttribute | undefined;
}

export function InputGroup({
  label = "",
  id,
  autoComplete,
  className,
  value,
  messageError,
  hasPasswordType = false,
  type = "text",
  ...props
}: InputGroupProps) {
  const [icon, setIcon] = useState<"visibility" | "visibility_off">(
    "visibility_off"
  );

  return (
    <>
      <Wrapper className={className}>
        {label ? <label htmlFor={id}>{label}</label> : ""}
        <Input
          type={
            !hasPasswordType
              ? type
              : icon === "visibility"
              ? "text"
              : "password"
          }
          id={id}
          autoComplete={autoComplete}
          value={value}
          {...props}
        />
        {hasPasswordType ? (
          <button
            type="button"
            onClick={() =>
              setIcon(icon === "visibility" ? "visibility_off" : "visibility")
            }
          >
            <IconGoogle>{icon}</IconGoogle>
          </button>
        ) : null}
      </Wrapper>
      <MessageError>{messageError}</MessageError>
    </>
  );
}
