import { IconGoogle } from "../../atoms";
import { NotificationWrapper, NotificationContent } from "./styles";

type NotificationProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  type?: "primary" | "danger" | "success";
  show?: boolean;
  setShow?: (event: boolean) => void;
};

enum Icon {
  "primary" = "info",
  "danger" = "report",
  "success" = "check_circle_outline",
}

export function Notification({
  type = "success",
  show,
  setShow,
  ...props
}: NotificationProps) {
  return show ? (
    <NotificationWrapper type={type}>
      <IconGoogle>{Icon[type]}</IconGoogle>
      <NotificationContent>{props.children}</NotificationContent>
      <button onClick={() => setShow!(false)}>
        <IconGoogle>close</IconGoogle>
      </button>
    </NotificationWrapper>
  ) : null;
}
