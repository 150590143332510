import React from "react";

import { ThemeProvider } from "styled-components";

import GlobalStyle from "./styles/global";
import Router from "./routes";
import { AuthProvider } from "./contexts/Auth/AuthProvider";
import { defaultTheme } from "./styles/themes/defaultTheme";
import { Loading } from "./components/atoms";
import { LoadingProvider } from "./contexts/LoadingCTX/LoadingProvider";
import { NotificationProvider } from "./contexts/NotificationCTX/NotificationProvider";

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={defaultTheme}>
        <LoadingProvider>
          <NotificationProvider>
            <>
              <GlobalStyle />
              <Loading active={false} />
              <Router />
            </>
          </NotificationProvider>
        </LoadingProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
