import { useEffect, useState } from "react";
import { IconGoogle } from "../../atoms";
import {
  PaginationButton,
  Wrapper,
  PaginationLegend,
  PaginationActions,
} from "./styles";

type PaginationProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  data: PaginationType;
  dispatch?: (event: { limit: number; count: number; offset: number }) => void;
};

export function Pagination({ className, data, dispatch }: PaginationProps) {
  const [controlLimit, setControlLimit] = useState(0);
  const [amountOfPages, setAmountOfPages] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState([1]);
  const [higherIndex, setHigherIndex] = useState(3);
  const [isTheFirstPage, setIsTheFirstPage] = useState(false);
  const [control, setControl] = useState(0);
  const [isItTheLastPage, setIsItTheLastPage] = useState(false);

  const currentGroup = numberOfPages.slice(higherIndex - 3, higherIndex);

  useEffect(() => {
    setAmountOfPages(Math.ceil(data.count / data.limit));
    setNumberOfPages(
      new Array(amountOfPages).fill(null).map((_, index) => index + 1)
    );
    setIsTheFirstPage(data.offset === 0);
    setControl(Math.round(data.offset / data.limit));
    setIsItTheLastPage(data.offset >= data.count - data.limit);

    if (controlLimit !== data.limit) {
      setControlLimit(data.limit);
      setHigherIndex(3);
    }
  }, [data, amountOfPages, controlLimit]);

  const setControlPagination = ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const pageTarget = Number(currentTarget.dataset.page);

    if (pageTarget > higherIndex) setHigherIndex(pageTarget);

    if (higherIndex - pageTarget >= 3) setHigherIndex(higherIndex - 1);

    if (pageTarget === 1) setHigherIndex(3);

    if (numberOfPages.includes(pageTarget)) {
      data.offset = (pageTarget - 1) * data.limit;
      dispatch!({ ...data });
    }
  };

  return (
    <Wrapper className={className}>
      <PaginationLegend>
        Mostrando {data.count > 0 ? data.offset + 1 : "0"} a{" "}
        {isItTheLastPage ? data.count : data.offset + data.limit} de{" "}
        {data.count} entradas
      </PaginationLegend>

      <PaginationActions>
        <PaginationButton
          aria-label="navegar para a primeira página"
          onClick={setControlPagination}
          data-page="1"
          disabled={isTheFirstPage}
        >
          <IconGoogle>keyboard_double_arrow_left</IconGoogle>
        </PaginationButton>
        <PaginationButton
          aria-label="previous"
          onClick={setControlPagination}
          data-page={control}
          disabled={isTheFirstPage}
        >
          <IconGoogle>chevron_left</IconGoogle>
        </PaginationButton>
        {currentGroup.map((page, index) =>
          index < 3 ? (
            <PaginationButton
              key={index}
              onClick={setControlPagination}
              className={page === control + 1 ? "active" : ""}
              data-page={page}
            >
              {page}
            </PaginationButton>
          ) : (
            ""
          )
        )}
        <PaginationButton
          aria-label="next"
          onClick={setControlPagination}
          data-page={control + 2}
          disabled={isItTheLastPage}
        >
          <IconGoogle>navigate_next</IconGoogle>
        </PaginationButton>
        <PaginationButton
          aria-label="next"
          onClick={setControlPagination}
          data-page={numberOfPages.at(-1)}
          disabled={isItTheLastPage}
        >
          <IconGoogle>keyboard_double_arrow_right</IconGoogle>
        </PaginationButton>
      </PaginationActions>
    </Wrapper>
  );
}
