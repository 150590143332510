import {
  ButtonWrapper,
  Image,
  ImageContainer,
  ImageWrapper,
  WrapperForm,
} from "./styles";
import { Button, Link } from "../../../components/atoms";
import { InputGroup } from "../../../components/molecules";
import { useNavigate } from "react-router-dom";

import loginIlustration from "../../../assets/img/banner_inicial.jpg";
import logoBiotur from "../../../assets/img/logo_biotur.png";
import { useContext, useEffect, useState } from "react";
import { useApi } from "../../../hooks/useApi";
import { AuthContext } from "../../../contexts/Auth/AuthContext";
import { NotificationContext } from "../../../contexts/NotificationCTX/NotificationContext";
import { LoadingContext } from "../../../contexts/LoadingCTX/LoadingContext";

export function ResetPassword() {
  const navigate = useNavigate();
  const loadingContext = useContext(LoadingContext);
  const { alterarSenha } = useApi();
  const {
    applicationData: { token, username },
    login,
  } = useContext(AuthContext);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showButton, setShowButton] = useState<boolean>(false);
  const [strengthErrorMessage, setStrengthErrorMessage] = useState("");
  const [equivalencyErrorMessage, setEquivalencyErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validPassword = (password: string) => {
    const regexp = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$"
    );
    return regexp.test(password);
  };

  const updatePassword = (password: string) => {
    setNewPassword(password);
  };

  const { setShow, setText: setTextAlert } = useContext(NotificationContext);

  useEffect(() => {
    (() => {
      if (newPassword === confirmPassword) {
        setEquivalencyErrorMessage("");
      } else {
        setEquivalencyErrorMessage("As senhas devem ser iguais");
      }

      if (oldPassword && newPassword && confirmPassword) {
        setShowButton(false);
      } else {
        setShowButton(true);
      }
    })();

    validPassword(newPassword)
      ? setStrengthErrorMessage("")
      : setStrengthErrorMessage("Senha fraca");

    updatePassword(newPassword);
  }, [confirmPassword, newPassword, oldPassword]);

  const handlePasswordChange = async () => {
    loadingContext.setLoading(true);

    const { data, status } = await alterarSenha(
      token,
      username,
      oldPassword,
      newPassword
    );

    if (status === 200 && data === "Senha alterada com sucesso") {
      try {
        setErrorMessage("");
        setTextAlert(data);
        setShow(true);

        const isLogged = await login({ usuario: username, senha: newPassword });

        if (isLogged) navigate("/");
      } catch {
        setErrorMessage("Não conseguimos prosseguir com a solicitação!");
      }
    }

    loadingContext.setLoading(false);
  };

  return (
    <main className="container-fluid">
      <div className="row align-items-center">
        <ImageWrapper>
          <ImageContainer>
            <Image
              src={loginIlustration}
              alt="Imagem ilustrativa"
              className="ilustracao"
            />
          </ImageContainer>
        </ImageWrapper>

        <WrapperForm>
          <img src={logoBiotur} alt="Logotipo da Biotur" />

          <h1>Alterar senha</h1>
          <p>Sua senha deve conter no mínimo 8 caracteres</p>

          <InputGroup
            id="oldPassword"
            type="password"
            hasPasswordType={true}
            label="Senha atual"
            onChange={(e: any) => {
              setOldPassword(e.target.value);
            }}
            value={oldPassword}
            messageError={errorMessage}
            required
          />
          <InputGroup
            id="newPassword"
            type="password"
            hasPasswordType={true}
            messageError={strengthErrorMessage}
            label="Nova senha"
            onChange={(e: any) => {
              setNewPassword(e.target.value);
            }}
            value={newPassword}
            required
          />

          <InputGroup
            id="confirmNewPassword"
            type="password"
            hasPasswordType={true}
            messageError={equivalencyErrorMessage}
            onChange={(e: any) => {
              setConfirmPassword(e.target.value);
            }}
            value={confirmPassword}
            label="Confirme a nova senha"
            required
          />

          <ButtonWrapper>
            <Button
              appearance="primary"
              disabled={showButton}
              onClick={handlePasswordChange}
            >
              Redefinir senha
            </Button>
          </ButtonWrapper>
          <span className="text-center">
            <Link to="auth/login">Voltar para login</Link>
          </span>
        </WrapperForm>
      </div>
    </main>
  );
}
