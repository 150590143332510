import styled from "styled-components";

export const Main = styled.main`
  @media (max-width: 992px) {
    margin-block: 32px 80px;
  }
`;

export const LinkStyle = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.dark};
  padding: 0;
  border-radius: 15px;
  height: 100%;
  overflow: hidden;

  & .ilustracao {
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
  }
`;

export const ImageWrapper = styled.div`
  height: 100%;
  padding: 40px;
  padding-right: 0;
`;

export const WrapperForm = styled.form`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 0 50px;

  h1 {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 32px;
  }
`;

export const Remember = styled.div`
  display: flex;
  gap: 10px;
`;

export const Divider = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  hr {
    flex: 1;
  }
`;

export const WrapperSocialAuth = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
  }
  [data-testid="GoogleIcon"] {
    font-size: 20px;
  }
`;
