import React from "react";

import { ButtonComponent } from "./styles";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  appearance?: "primary" | "secondary" | "danger" | "success" | "dark";
}

export function Button({
  appearance = "primary",
  children,
  type = "button",
  onClick,
  disabled,
}: ButtonProps) {
  return (
    <ButtonComponent
      appearance={appearance}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </ButtonComponent>
  );
}
