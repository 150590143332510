import React from "react";

interface IconProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  appearance?: "primary" | "secondary" | "danger" | "success" | "dark";
}

export function IconGoogle({ children, className = "" }: IconProps) {
  return (
    <i
      lang="en"
      translate="no"
      className={`${className + " material-icons-outlined"}`}
    >
      {children}
    </i>
  );
}
