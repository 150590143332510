import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  padding-inline: 12px;

  @media (min-width: 768px) {
    flex-direction: row;

    button {
      width: auto;
    }
  }
`;

export const WrapperTuorList = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 20px;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const WrapperInformPassengers = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 20px;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;
