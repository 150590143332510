import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../contexts/Auth/AuthContext";
import { IconGoogle } from "../../atoms";
import { NavListItem } from "../../molecules";

import { Nav } from "./styles";

export function NavList() {
  const {
    signout,
    applicationData: { rules },
  } = useContext(AuthContext);
  const pathname = useLocation().pathname;

  const [asideModel, setAsideModel] = useState<AsideModel>([]);

  useEffect(() => {
    setAsideModel(rules);
  }, [rules]);

  const checkExpandedGroup = (item: GroupNaveItemModel) => {
    return item.group?.reduce((acc, subItem) => {
      for (const iterator in subItem) {
        if (iterator === "route") {
          const validation = pathname.search(subItem[iterator]!) === 1;
          if (validation) acc = validation;
        }
      }
      return acc;
    }, false);
  };

  return (
    <Nav>
      <ul>
        {asideModel.map((item, idItem) =>
          item.hasOwnProperty("group") ? (
            <li key={"item" + idItem}>
              <NavListItem.NavListButton
                expanded={checkExpandedGroup(item)}
                icon={item.icon}
                text={item.text}
                onClick={(e) => {
                  e.currentTarget.classList.toggle("expanded");
                }}
              />

              <ul>
                {item.group?.map((subItem, idSubItem) => (
                  <li key={"subItem" + idSubItem}>
                    <NavListItem.NavListLink
                      to={subItem.route}
                      icon={subItem.icon}
                      text={subItem.text}
                    />
                  </li>
                ))}
              </ul>
            </li>
          ) : (
            <li key={"item" + idItem}>
              <NavListItem.NavListLink
                to={item.route}
                icon={item.icon}
                text={item.text}
              />
            </li>
          )
        )}

        <hr className="line-top" />
        <li>
          <button className="logout" onClick={(e) => signout()}>
            <IconGoogle>logout</IconGoogle>
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </Nav>
  );
}
