import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;

  /* reset list */
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;

      a,
      button {
        min-height: 40px;
        width: calc(100% - 28px);
        display: flex;
        align-items: center;
        margin-inline: 14px;
        text-decoration: none;
        background: transparent;
        color: ${({ theme }) => theme.colors.text};
        border-radius: 8px;
        padding: 0 10px;
        border: none;
        gap: 8px;

        &.expanded,
        &:hover {
          background: ${({ theme }) => theme.colors.background_800 + "23"};
        }

        span {
          flex: 1;
          text-align: start;
        }

        i.icon-children {
          font-size: 18px;
          margin-left: 10px;
        }
      }

      button:not(.logout) {
        background: ${({ theme }) => theme.colors.background_800 + "23"};

        .group-toggle {
          transition: rotate 0.3s ease-in-out;
        }
      }

      ul {
        overflow: hidden;
        height: 0;
        animation: collapsed 0.3s linear;
      }

      &:has(.expanded) {
        ul {
          height: auto;
          animation: expanded 0.3s linear;
        }

        .group-toggle {
          rotate: 90deg;
        }
      }
    }

    a {
      transition: background 0.3s ease-in-out;
      &.active {
        background: ${({ theme }) => theme.colors.primary} !important;
        color: ${({ theme }) => theme.colors.light};
      }
    }
  }

  .line-top {
    border-top: 1px solid ${({ theme }) => theme.colors.secondary};
  }

  @keyframes expanded {
    0% {
      height: 0;
    }
    25% {
      height: 22px;
    }
    50% {
      height: 44px;
    }
    75% {
      height: 66px;
    }
    100% {
      height: auto;
    }
  }

  @keyframes collapsed {
    0% {
      height: auto;
    }
    25% {
      height: 66px;
    }
    50% {
      height: 44px;
    }
    75% {
      height: 22px;
    }
    100% {
      height: 0;
    }
  }
`;
