import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { AuthContext } from "./AuthContext";

export function AuthProvider({ children }: { children: JSX.Element }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [logged, setLogged] = useState<boolean>(false);
  const [applicationData, setApplicationData] = useState<any>({});

  const api = useApi();

  useEffect(() => {
    setLoading(true);

    (async () => {
      const storageData = JSON.parse(localStorage.getItem("webtour-data")!);

      if (storageData) {
        setApplicationData(storageData);
        setLogged(true);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (user: User) => {
    const data = await api.login(user);
    if (data.token) {
      setUser(user);

      const destinos = await api.destinos(data.token);
      data.destinos = destinos;

      const clientes = await api.clientes(data.token);
      data.clientes = clientes;

      setApplicationData(data);

      setData(data);

      if (data) setLogged(true);

      return true;
    }

    return false;
  };

  const signout = () => {
    setLogged(false);
    setData("");
  };

  const setData = (data: any) => {
    localStorage.setItem("webtour-data", JSON.stringify(data));
  };

  return (
    <AuthContext.Provider
      value={{ user, logged, login, signout, loading, applicationData }}
    >
      {children}
    </AuthContext.Provider>
  );
}
