import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  flex-direction: column;
  overflow-x: auto;
`;

export const DetailsTable = styled.table`
  width: 100%;
  margin-bottom: 1rem;
`;

export const TableHeader = styled.thead`
  border-top: 0.1rem solid #ccc;
  border-bottom: 0.1rem solid #ccc;
  background-color: #fcfdff;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  border-top: 0.1rem solid #ccc;
`;

export const TableColumn = styled.th`
  font-weight: 700;
  padding: 0.8rem 0.5rem 0.8rem 0.5rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  opacity: 0.9;
`;

export const TableCell = styled.td`
  padding: 0.8rem 0.5rem 0.8rem 0.5rem;
  opacity: 0.9;
  transition: 0.3s;

  :hover {
    background-color: #f1f3f5;
  }

  @media (max-width: 576px) {
    font-size: 0.8rem;
  }
`;

export const EmptyTableWrapper = styled.div`
  margin: 2rem 0.9rem 0rem 0rem;
`;

export const EmptyTable = styled.p`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme }) => theme.colors.background_800 + "22"};
  padding: 12px 16px;
  border-radius: 8px;

  i {
    color: ${({ theme }) => theme.colors.danger};
  }
`;
