import { Aside } from "../../components/organisms";

import { Container, Header, Main, ShowAside } from "./styles";

import logoBiotur from "../../assets/img/logo_biotur.png";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IconGoogle } from "../../components/atoms";

export function Home() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [showAside, setShowAside] = useState(false);

  useEffect(() => {
    if (pathname === "/") {
      navigate("operacional/reservas");
    }
  }, [pathname, navigate]);

  return (
    <Container className="container-fluid">
      <Aside
        state={showAside}
        toggle={setShowAside}
        logo={<img src={logoBiotur} alt="Logo marca da empresa" />}
      />
      <Header className="mb-3">
        <img src={logoBiotur} alt="Logo marca da empresa" />

        <ShowAside onClick={(e) => setShowAside(true)}>
          <IconGoogle>menu</IconGoogle>
        </ShowAside>
      </Header>
      <Main className="container-fluid">
        <Outlet />
      </Main>
    </Container>
  );
}
