import styled from "styled-components";

type NotificationWrapperProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  type?: "primary" | "danger" | "success";
};

export const NotificationWrapper = styled.div<NotificationWrapperProps>`
  background-color: ${({ theme, type }) => theme.colors[type || "primary"]};
  color: #fff;
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(33, 37, 41, 0.15);
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 48px;
  min-width: 300px;
  width: 100%;
  max-width: 750px;
  z-index: 9999;

  & > i {
    font-size: 36px;
  }

  button {
    background-color: transparent;
    color: inherit;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: none;
  }
`;

export const NotificationContent = styled.div`
  flex-grow: 1;
`;
