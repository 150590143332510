import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 5px 13px;
  outline: ${({ theme }) => `1px solid ${theme.colors.secondary}55`};
  border: none;
  border-radius: 5px;
  margin-top: 15px;
  width: 100%;

  label {
    position: absolute;
    left: 13px;
    line-height: 15px;
    padding: 0 4px;
    transform: translateY(-13px);
    display: inline-block;
    transition: all 0.1s ease-in-out;
    font-size: 12px;

    &:after {
      content: "";
      background-color: ${({ theme }) => theme.colors.background};
      position: absolute;
      top: 0;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      z-index: -1;
    }
  }

  input {
    background: transparent;
    width: 100%;

    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &:has(input:focus) {
    outline: ${({ theme }) => `2px solid ${theme.colors.primary}`};

    label {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  button {
    border: 0;
    background-color: transparent;
    line-height: 0;
  }
`;
