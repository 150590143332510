import { WrapperLoading } from "./styles";

interface loadingProps {
  active: boolean;
}

export function Loading({ active }: loadingProps) {
  return (
    <>
      {active ? (
        <WrapperLoading aria-label="carregando...">
          <span className="loader"></span>
        </WrapperLoading>
      ) : (
        ""
      )}
    </>
  );
}
