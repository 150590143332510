import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.dark};
  min-height: 100vh;
  padding: 20px 30px;
  padding-inline-start: 270px;

  @media (max-width: 992px) {
    padding: 0px;
  }
`;

export const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 3px 9px 1px rgba(12, 16, 27, 0.15),
    0 9px 8px rgba(12, 16, 27, 0.01), 0 1px 6px 4px rgba(12, 16, 27, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 992px) {
    display: none;
  }

  img {
    max-width: 150px;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 40px);
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 3px 9px 1px rgba(12, 16, 27, 0.15),
    0 9px 8px rgba(12, 16, 27, 0.01), 0 1px 6px 4px rgba(12, 16, 27, 0.08);
`;

export const ShowAside = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.light};
  padding: 6px;
  padding-bottom: 0px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary + "dd"};
  }
`;
