import React, { useContext, useEffect, useState } from "react";

// Components
import { Button, Link } from "../../components/atoms";
import { InputGroup } from "../../components/molecules";

// Components Styled Component
import {
  ButtonWrapper,
  ImageContainer,
  ImageWrapper,
  WrapperForm,
} from "./styles";

// Imagens
import loginIlustration from "../../assets/img/banner_inicial.jpg";
import logoBiotur from "../../assets/img/logo_biotur.png";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../contexts/NotificationCTX/NotificationContext";
import { useApi } from "../../hooks/useApi";

const isEmail = (email: string) => {
  const regexp = /\S+@\S+\.\S+/;
  return regexp.test(email);
};

const MESSAGE_ERROR_EMAIL = "Favor digitar um email válido!";

const DynamicRoutesTexts: {
  [key: string]: any | Object;
} = {
  cadastro: { title: "Cadastro", description: "Criar uma nova conta." },
  "esqueci-minha-senha": {
    title: "Recuperar Acesso",
    description: "Informe o e-mail utilizado para acesso",
  },
};

export function AuthScreen() {
  const navigate = useNavigate();
  const { criarConta, esqueceuSenha } = useApi();
  const {
    setType,
    setShow,
    setText: setTextAlert,
  } = useContext(NotificationContext);
  const { screen } = useParams();
  const text = DynamicRoutesTexts[`${screen}`];
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setType("success");
  }, [setType]);

  const createAccount = async () => {
    const { data, status } =
      screen === "cadastro"
        ? await criarConta(email)
        : await esqueceuSenha(email);

    if (status !== 200) {
      setErrorMessage("Não conseguimos prosseguir com a solicitação!");
    } else if (data === "Email já cadastrado") {
      setErrorMessage("Email já cadastrado");
    } else {
      setErrorMessage("");
      setTextAlert(data);
      setShow(true);
      navigate("/");
    }
  };

  const resetPassword = async () => {
    const { data, status } =
      screen === "esqueci-minha-senha"
        ? await esqueceuSenha(email)
        : await criarConta(email);

    if (status !== 200) {
      setErrorMessage("Não conseguimos prosseguir com a solicitação!");
    } else if (data === "Email já cadastrado") {
      setErrorMessage("Email já cadastrado");
    } else {
      setErrorMessage("");
      setTextAlert(data);
      setShow(true);
      navigate("/auth/login");
    }
  };

  return (
    <main className="container-fluid vh-100">
      <div className="row vh-100 align-items-center">
        <ImageWrapper className="col-sm-12 col-md-6 col-lg-8 d-none d-md-block">
          <ImageContainer>
            <img
              src={loginIlustration}
              alt="Imagem ilustrativa"
              className="ilustracao"
            />
          </ImageContainer>
        </ImageWrapper>

        <WrapperForm className="col-sm-12 col-md-6 col-lg-4">
          <img src={logoBiotur} alt="Logotipo da Biotur" />

          <h1>{text?.title}</h1>
          <p>{text?.description}</p>

          <InputGroup
            id="email"
            value={email}
            label="Email"
            messageError={errorMessage}
            onChange={(e) => {
              setEmail(e.target.value);

              isEmail(e.target.value)
                ? setErrorMessage("")
                : setErrorMessage(MESSAGE_ERROR_EMAIL);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && isEmail(email)) {
                createAccount();
              } else {
                setErrorMessage(MESSAGE_ERROR_EMAIL);
              }
            }}
            required
          />

          <ButtonWrapper>
            <Button
              appearance="primary"
              onClick={() => {
                if (isEmail(email)) {
                  resetPassword();
                } else {
                  setErrorMessage(MESSAGE_ERROR_EMAIL);
                }
              }}
            >
              Adquirir senha provisória
            </Button>
          </ButtonWrapper>
          <span className="text-center">
            <Link to="/auth/login">Voltar para login</Link>
          </span>
        </WrapperForm>
      </div>
    </main>
  );
}
