import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`;

export const ButtonWrapper = styled.div`
  margin: 1.25rem 0;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;

  height: 50%;
  overflow: hidden;

  & .ilustracao {
    object-fit: cover;
    opacity: 0.8;
  }

  /* & .base-fundo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  } */
`;

export const ImageWrapper = styled.div`
  height: 50%;
  padding: 4px;
`;

export const Image = styled.img`
  max-width: 90%;
  min-width: 55%;
  height: 250px;
  border-radius: 15px;
`;

export const WrapperForm = styled.form`
  margin-top: 4rem;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 35%;

  h1 {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 1.75rem;
    font-weight: 600;
    opacity: 0.9;
    margin: 15px 0;
  }

  @media (max-width: 992px) {
    padding: 0 20%;
  }

  @media (max-width: 576px) {
    padding: 0 25px;
  }
`;

export const Divider = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  hr {
    flex: 1;
  }
`;
