import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, IconGoogle } from "../../../../components/atoms";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import { useApi } from "../../../../hooks/useApi";

import {
  ButtonWrapper,
  Description,
  Detail,
  DetailsGroup,
  DetailType,
  Wrapper,
} from "./styles";

const theObjectsAreTheSame = (objA: Object, objB: Object) => {
  return JSON.stringify(objA) === JSON.stringify(objB);
};

export function Details() {
  const { reservationID } = useParams();
  const { detalheReserva } = useApi();
  const {
    applicationData: { token, username },
  } = useContext(AuthContext);

  const [detail, setDetail] = useState<any>({
    origem: "",
    destino: "",
    rota: "",
    embarque: null,
    reserva: 0,
    adultos: 0,
    criancas: 0,
    preco: 0,
    agerba: 0,
    taxa_embarque: 0,
    valor_total: 0,
    data_reserva: "",
    transacao: null,
    status: null,
  });
  useEffect(() => {
    (async () => {
      const reservationInfo = await detalheReserva(
        token,
        username,
        reservationID!
      );
      if (reservationInfo && !theObjectsAreTheSame(detail, reservationInfo)) {
        setDetail((current: any) => ({ ...current, ...reservationInfo }));
      }
    })();
  }, [detalheReserva, username, reservationID, token, detail]);

  return detail ? (
    <Wrapper>
      <h1>Detalhes</h1>
      <DetailsGroup>
        <DetailType>Reserva</DetailType>
        <Detail>
          <IconGoogle>key</IconGoogle>
          <Description>Número da reserva: {detail.reserva}</Description>
        </Detail>
        <Detail>
          <IconGoogle>person</IconGoogle>
          <Description>Adultos: {detail.adultos || 0}</Description>
        </Detail>
        <Detail>
          <IconGoogle>face</IconGoogle>
          <Description>Crianças: {detail.criancas || 0}</Description>
        </Detail>
        <Detail>
          <IconGoogle>location_on</IconGoogle>
          <Description>Origem: {detail.origem || null}</Description>
        </Detail>
        <Detail>
          <IconGoogle>pin_drop</IconGoogle>
          <Description>Destino: {detail.destino || null}</Description>
        </Detail>
      </DetailsGroup>
      <DetailsGroup>
        <DetailType>Datas</DetailType>
        <Detail>
          <IconGoogle>event_available</IconGoogle>
          <Description>
            Data da reserva:{" "}
            {new Date(detail.data_reserva).toLocaleDateString()}
          </Description>
        </Detail>
        <Detail>
          <IconGoogle>calendar_month</IconGoogle>
          <Description>Embarque: {detail.embarque || null}</Description>
        </Detail>
      </DetailsGroup>
      <DetailsGroup>
        <DetailType>Valores</DetailType>
        <Detail>
          <IconGoogle>request_quote</IconGoogle>
          <Description>
            Agerba: R${detail.agerba.toLocaleString("pt-BR")}
          </Description>
        </Detail>
        <Detail>
          <IconGoogle>payments</IconGoogle>
          <Description>
            Total: R${detail.valor_total.toLocaleString("pt-BR")}
          </Description>
        </Detail>
      </DetailsGroup>
      <DetailsGroup>
        <DetailType>Situação</DetailType>
        <Detail>
          <IconGoogle>manage_search</IconGoogle>
          <Description>Status: {detail.status || null}</Description>
        </Detail>
      </DetailsGroup>

      <ButtonWrapper>
        <Button
          onClick={() => {
            window.location.href = "/operacional/relreservas";
          }}
        >
          Voltar para minhas reservas
        </Button>
      </ButtonWrapper>
    </Wrapper>
  ) : null;
}
